import axiosInstance from "../api/axios.instance";

export const getUnpaidUnits = async () => {
  try {
    const { data } = await axiosInstance.get("/users/unpaidUnits/personal", {
      params: {
        sort: "desc",
      },
    });

    if (data.status === "warning") {
      return { warning: data.message };
    }

    if (data.status === "success") {
      return { data: data.apiData };
    }
  } catch (err) {
    console.error(err);

    return {
      error: err?.response?.data?.message || "Server-side error",
    };
  }
};
